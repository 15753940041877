import React, { useContext } from 'react'
import StoreContext from '~/context/StoreContext'
import { Overlay, ProductImage } from './styles'

const ImageOverlay = () => {
  const {
    store: { activeImage },
    updateActiveImage,
  } = useContext(StoreContext)

  return (
    activeImage && (
      <Overlay onClick={() => updateActiveImage(null)}>
        <ProductImage fluid={activeImage} loading="eager" />
      </Overlay>
    )
  )
}

export default ImageOverlay
