import styled from '@emotion/styled'
import { breakpoints } from '~/utils/styles'
import Image from 'gatsby-image'

export const Container = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 1rem 2rem;
  display: flex;
  flex-direction: column;
  min-width: 320px;
  @media (max-width: ${breakpoints.s}px) {
    bottom: 0px;
    width: 100%;
    text-align: center;
    padding: 1rem;
  }
`

export const ProductTitle = styled.h1`
  font-size: 1rem;
  text-transform: uppercase;
  word-wrap: break-word;
  font-family: 'Helvetica', 'Helvetica', sans-serif;
  font-weight: 400;
  margin: 0 0 0.5rem;
  line-height: 1.4;
`

export const ProductDescription = styled.div`
  font-family: 'Helvetica', 'Helvetica', sans-serif;
  font-weight: 300;
  font-size: 0.9rem;
  line-height: 1.4;
  overflow: auto;
  max-height: 100px;
  max-width: 400px;
  @media (max-width: ${breakpoints.s}px) {
    margin: 0 auto;
    text-align: center;
  }

  p {
    margin: 0;
  }
`
export const ImageContainer = styled.div`
  display: flex;
  margin: 0 0 10px;
  @media (max-width: ${breakpoints.s}px) {
    margin: 0 auto 10px;
  }
`

export const ProductImage = styled(Image)`
  height: 50px;
`
export const ImageClickContainer = styled.div`
  margin-right: 10px;
  cursor: zoom-in;
`
