import React, { useState, useContext, useEffect, useCallback } from 'react'
import find from 'lodash/find'
import isEqual from 'lodash/isEqual'
import PropTypes from 'prop-types'

import StoreContext from '~/context/StoreContext'
import {
  Container,
  Price,
  Buy,
  AddButton,
  OptionContainer,
  OptionLabel,
  OptionList,
  OptionValue,
} from './styles'

const ProductForm = ({ product }) => {
  const {
    options,
    variants,
    variants: [initialVariant],
    priceRange: { minVariantPrice },
  } = product
  const [variant, setVariant] = useState({ ...initialVariant })
  // const [quantity, setQuantity] = useState(1)
  const {
    addVariantToCart,
    store: { client, adding },
  } = useContext(StoreContext)

  const productVariant =
    client.product.helpers.variantForOptions(product, variant) || variant
  const [available, setAvailable] = useState(productVariant.availableForSale)

  const checkAvailability = useCallback(
    productId => {
      client.product.fetch(productId).then(fetchedProduct => {
        // this checks the currently selected variant for availability
        const result = fetchedProduct.variants.filter(
          variant => variant.id === productVariant.shopifyId
        )
        if (result.length > 0) {
          setAvailable(result[0].available)
        }
      })
    },
    [client.product, productVariant.shopifyId, variants]
  )

  useEffect(() => {
    checkAvailability(product.shopifyId)
  }, [productVariant, checkAvailability, product.shopifyId])

  // const handleQuantityChange = ({ target }) => {
  //   setQuantity(target.value)
  // }

  const handleOptionChange = (optionIndex, value) => {
    const currentOptions = [...variant.selectedOptions]

    currentOptions[optionIndex] = {
      ...currentOptions[optionIndex],
      value,
    }

    const selectedVariant = find(variants, ({ selectedOptions }) =>
      isEqual(currentOptions, selectedOptions)
    )

    setVariant({ ...selectedVariant })
  }

  const handleAddToCart = () => {
    // last arg = quantity
    addVariantToCart(productVariant.shopifyId, 1)
  }

  /* 
  Using this in conjunction with a select input for variants 
  can cause a bug where the buy button is disabled, this 
  happens when only one variant is available and it's not the
  first one in the dropdown list. I didn't feel like putting 
  in time to fix this since its an edge case and most people
  wouldn't want to use dropdown styled selector anyways - 
  at least if the have a sense for good design lol.
  */

  // figure out how to select first of available

  const checkDisabled = (name, value) => {
    const match = find(variants, {
      selectedOptions: [
        {
          name: name,
          value: value,
        },
      ],
    })
    if (match === undefined) return true
    if (match.availableForSale === true) return false
    return true
  }

  const handleKeyPress = (e, index, value) => {
    if (e.key === 'Enter') {
      handleOptionChange(index, value)
    }
  }

  const price = Intl.NumberFormat(undefined, {
    currency: minVariantPrice.currencyCode,
    minimumFractionDigits: 2,
    style: 'currency',
  }).format(variant.price)

  return (
    <Container>
      <Buy>
        <Price>{price}</Price>

        {options[0].values[0] !== 'Default Title' &&
          options.map(({ id, name, values }, index) => (
            <OptionContainer key={id}>
              <OptionLabel htmlFor={name}>{name}:</OptionLabel>

              <OptionList>
                {values.map(value => {
                  const disabled = checkDisabled(name, value)
                  return (
                    <OptionValue
                      key={`${name}-${value}`}
                      disabled={disabled}
                      onClick={() =>
                        !disabled && handleOptionChange(index, value)
                      }
                      selected={value === variant.title}
                      role="button"
                      aria-pressed={!!(value === variant.title)}
                      tabIndex={!disabled ? 0 : -1}
                      onKeyPress={e => handleKeyPress(e, index, value)}
                    >
                      {value}
                    </OptionValue>
                  )
                })}
              </OptionList>
            </OptionContainer>
          ))}

        {available ? (
          <AddButton
            type="submit"
            disabled={!available || adding}
            onClick={handleAddToCart}
          >
            {!adding ? 'Add to Cart' : 'Adding'}
          </AddButton>
        ) : (
          <p>Out of stock.</p>
        )}
      </Buy>
    </Container>
  )
}

ProductForm.propTypes = {
  product: PropTypes.shape({
    descriptionHtml: PropTypes.string,
    handle: PropTypes.string,
    id: PropTypes.string,
    shopifyId: PropTypes.string,
    images: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        originalSrc: PropTypes.string,
      })
    ),
    options: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        values: PropTypes.arrayOf(PropTypes.string),
      })
    ),
    productType: PropTypes.string,
    title: PropTypes.string,
    variants: PropTypes.arrayOf(
      PropTypes.shape({
        availableForSale: PropTypes.bool,
        id: PropTypes.string,
        price: PropTypes.string,
        title: PropTypes.string,
        shopifyId: PropTypes.string,
        selectedOptions: PropTypes.arrayOf(
          PropTypes.shape({
            name: PropTypes.string,
            value: PropTypes.string,
          })
        ),
      })
    ),
  }),
  addVariantToCart: PropTypes.func,
}

export default ProductForm
