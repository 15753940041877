import styled from '@emotion/styled'
import { breakpoints } from '~/utils/styles'
import { Button } from '~/utils/styles'

export const Container = styled.div`
  @media (max-width: ${breakpoints.s}px) {
    display: flex;
    justify-content: center;
  }
`
export const Price = styled.h3`
  display: block;
  margin: 0.5rem 0 1rem;
  font-size: 1.2rem;
`
export const Buy = styled.div``

export const AddButton = styled(Button)`
  margin-bottom: 0;
  width: 10rem;
`
export const OptionContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const OptionLabel = styled.label`
  margin-right: 10px;
`

export const OptionList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0 0;
  display: flex;
  @media (max-width: ${breakpoints.s}px) {
    justify-content: center;
  }
`

export const OptionValue = styled.li`
  padding: 0.3rem 0.5rem;
  margin-right: 0.5rem;
  border: 1px solid ${props => (props.selected ? 'rgb(206, 255, 0)' : 'white')};
  color: ${props => (props.selected ? 'black' : 'white')};
  opacity: ${props => (props.disabled ? 0.3 : 1)};
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  background: ${props => (props.selected ? 'rgb(206, 255, 0)' : 'transparent')};
  &:focus {
    outline: 0;
    border-bottom: 1px solid rgb(206, 255, 0);
  }
`
