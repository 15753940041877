import React, { useContext, useEffect } from 'react'
import { graphql } from 'gatsby'
import StoreContext from '~/context/StoreContext'
import SEO from '~/components/seo'
import ProductForm from '~/components/Shop/ProductForm'
import Shop from '~/components/Shop'
import ImageOverlay from '~/components/ImageOverlay'
import axios from 'axios'

import {
  Container,
  ProductTitle,
  ProductDescription,
  ImageContainer,
  ProductImage,
  ImageClickContainer,
} from './styles'

const ProductPage = ({ data }) => {
  const product = data.shopifyProduct
  const { updateBackground, updateActiveImage } = useContext(StoreContext)

  async function fetchVideo() {
    return axios({
      url: `https://${process.env.SHOP_NAME}.myshopify.com/api/2020-07/graphql.json`,
      method: 'post',
      headers: {
        'X-Shopify-Storefront-Access-Token': process.env.SHOPIFY_ACCESS_TOKEN,
      },
      data: {
        query: `
            query {
              shop {
              productByHandle(handle: "${product.handle}") {
                id
                media(first: 10) {
                  edges {
                  node {
                    mediaContentType
                    ... on Video {
                      id
                      sources {
                      format
                      height
                      mimeType
                      url
                      width
                    }
                  }
                  }
                }
              }
            }
          }
        }
            `,
      },
    }).then(result => {
      const video = result.data.data.shop.productByHandle.media.edges.find(
        edge => edge.node.mediaContentType === 'VIDEO'
      )

      if (video) {
        return video.node.sources.find(source => source.format === 'mp4')
      }
      return false
    })
  }

  useEffect(() => {
    const { images } = product

    fetchVideo()
      .then(video =>
        updateBackground({
          video: video ? !!video.url : false,
          asset: video ? video.url : images[images.length - 1].originalSrc,
        })
      )
      .catch(error => console.error(error))

    // eslint-disable-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <SEO
        title={product.title}
        description={product.description}
        image={
          data.ogimage.images.length &&
          data.ogimage.images[0].localFile &&
          data.ogimage.images[0].localFile.childImageSharp.fixed.src
        }
      />
      <Shop />
      <ImageOverlay></ImageOverlay>
      <Container>
        {product.images.length ? (
          <ImageContainer>
            {product.images.map(image => (
              <ImageClickContainer
                key={'div-' + image.id}
                onClick={() =>
                  updateActiveImage(image.localFile.childImageSharp.fluid)
                }
              >
                <ProductImage
                  fixed={image.localFile.childImageSharp.fixed}
                  loading="eager"
                  key={image.id}
                  alt={product.title}
                />
              </ImageClickContainer>
            ))}
          </ImageContainer>
        ) : (
          'No images available.'
        )}
        <ProductTitle>{product.title}</ProductTitle>
        <ProductDescription
          dangerouslySetInnerHTML={{ __html: product.descriptionHtml }}
        />

        <ProductForm product={product} />
      </Container>
    </>
  )
}

export const query = graphql`
  query($handle: String!) {
    shopifyProduct(handle: { eq: $handle }) {
      id
      title
      handle
      productType
      description
      descriptionHtml
      shopifyId
      options {
        id
        name
        values
      }
      variants {
        id
        title
        price
        availableForSale
        shopifyId
        selectedOptions {
          name
          value
        }
      }
      priceRange {
        minVariantPrice {
          amount
          currencyCode
        }
        maxVariantPrice {
          amount
          currencyCode
        }
      }
      images {
        originalSrc
        id
        localFile {
          childImageSharp {
            fluid(maxWidth: 910) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
            fixed(height: 50) {
              ...GatsbyImageSharpFixed_withWebp_noBase64
            }
          }
        }
      }
    }
    ogimage: shopifyProduct(handle: { eq: $handle }) {
      images {
        originalSrc
        id
        localFile {
          childImageSharp {
            fixed(height: 627) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    }
  }
`

export default ProductPage
