import styled from '@emotion/styled'
import Image from 'gatsby-image'

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 30;
  cursor: zoom-out;
`

export const ProductImage = styled(Image)`
  margin: 0 auto;
  width: 60%;
  min-width: 400px;
  max-height: 100vh;
  padding: 2rem 2rem;
  cursor: zoom-out;
`
